import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from 'gatsby'

const ArchiveFilters = ({field_component, pagination_slug}) => {
    const data = useStaticQuery(graphql`
        query BlogArchiveFilterQuery {
            tags: allStoryblokEntry {
                group(field: tag_list) {
                    fieldValue
                    edges {
                        node {
                            field_component
                        }
                    }
                }
            }
        }
    `)

    // Filter out the ones we need only
    const tags = data.tags.group.filter(item => {
        return item.edges[0].node.field_component === field_component
    })

    return (
        <div className="bg-lightgray">
            <div className="max-w-7xl mx-auto px-6 py-6 flex flex-row flex-wrap gap-6 justify-center items-center">
                <div className="flex flex-row lg:justify-start justify-center items-center flex-wrap gap-y-4 text-offblack text-sm divide-x divide-offblack">
                    <Link
                        to={`/${pagination_slug}`}
                        className="pr-4"
                        activeClassName="!font-medium"
                    >
                    All
                    </Link>
                    {tags.map((item) => (
                        <Link
                            to={`/${pagination_slug}/category/${item.fieldValue.replace(/\s/g, "-").toLowerCase()}`}
                            className="px-4"
                            activeClassName="!font-medium"
                            key={item.fieldValue}
                        >
                        {item.fieldValue}
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ArchiveFilters
