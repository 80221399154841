import React from "react"
import ArchiveItem from './ArchiveItem'
import ArchiveFilters from "./ArchiveFilters"
import ArchivePagination from "./ArchivePagination"
import FullDepthHeader from 'src/components/TBB/FullDepthHeader'
import CtaBlocks from 'src/components/TBB/CtaBlocks'

const Archive = ({ posts, numPages, currentPage, pagination_slug, field_component, tagSlug, disable_tags, label, title }) => {
  return (
    <>
        <FullDepthHeader
            blok={{
                label: label,
                title: title
            }}
        />
        {!disable_tags && <ArchiveFilters field_component={field_component} pagination_slug={pagination_slug}/>}
        <section className="bg-white py-12 sm:py-24 px-6">
            <div className="relative mx-auto max-w-7xl">
                <div className="grid md:grid-cols-3 gap-6">
                    {posts.map((item, index) => (
                        <ArchiveItem {...item.node} key={index} disable_tags={disable_tags}/>
                    ))}
                </div>
            </div>
        </section>
        <ArchivePagination props={{numPages: numPages, currentPage: currentPage, pagination_slug: pagination_slug, tagSlug: tagSlug}}/>
        <CtaBlocks
            blok={{
                settings: '4'
            }}
        />
    </>
  )
}

export default Archive