import React from 'react'
import Heading from 'src/components/Heading'
import FluidImage from 'src/utils/FluidImage'
import { Link } from 'gatsby'
import resolveLink from 'src/utils/resolveLink'
import Button from "src/components/Button"

const ArchiveItem = (props) => {
    const data = JSON.parse(props.content)
    return (
        <Link to={resolveLink(props.full_slug)} className='group relative flex flex-col h-full pb-6'>
            <FluidImage className="w-full mb-7"  image={data.featured_image} placeholder="https://via.placeholder.com/395x395"/>
            {
                props.field_component == 'client' &&
                    <Heading size="h5" className={`capitalize !text-sm text-coral ${!data.location && '!mb-9'}`}>
                        {data.location}
                    </Heading>
            }
            {
                props.field_component == 'press_room' &&
                    <Heading size="h5" className={`capitalize !text-sm text-coral ${!props.tag_list.length === 0 && '!mb-0'}`}>
                        {
                            props.tag_list.map((tag, index) => (
                                (index == 0 && props.tag_list.length < 2 ) || index == (props.tag_list.length - 1) ?
                                tag+' '
                                :
                                tag+', '
                            ))
                         }
                    </Heading>
            }
            <Heading size="h3" className="!text-xl text-lagoon flex flex-1">
             {
             props.field_component == 'client' &&
                data.client_name
             }
             {['career', 'word', 'press_room'].includes(props.field_component) &&
                props.name
             }
            </Heading>                                      
            <div className="!text-sm uppercase !text-offblack font-semibold transition ease-in-out duration-300 group-hover:!text-vivid group-hover:translate-x-1">
                Read more
                <i className={`fas fa-chevron-right text-xs order-last ml-3`}></i>
            </div> 
            <div className="border-b border-darkgray pb-10"></div>
        </Link>
    )
};

export default ArchiveItem