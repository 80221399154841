import React from "react";
import Archive from "../components/ArchivesNew/Archive";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import { PageContext } from "src/utils/context";

const UniversalArchive = (props) => {
    let content = JSON.parse(props.data.settings.edges[0].node.content)
    let parsedSettings = Object.assign({}, content, {content: content})
    let getLabel
    let getTitle

    // Loop through and get the correct description
    for (const key in parsedSettings) {
        if (key === props.pageContext.archive_label_field) {
            getLabel = parsedSettings[key];
        }
        if (key === props.pageContext.archive_title_field) {
            getTitle = parsedSettings[key];
        }
    }
    
    let location
    if(props.pageContext.field_component.replace('_', '-') == 'word') {
      location = { pathname: '/words/'}
    } else {
      location = { pathname: '/'+props.pageContext.field_component.replace('_', '-')+'/' };
    }

    return (
    <PageContext.Provider
            value={{
            location: location
            }}
        >  
        <Layout location={location}>
            <Archive 
                posts={props.data.posts.edges} 
                numPages={props.pageContext.numPages} 
                currentPage={props.pageContext.currentPage}
                field_component={props.pageContext.field_component}
                pagination_slug={props.pageContext.slug}
                tagSlug={props.pageContext.tagSlug}
                disable_tags={props.pageContext.disable_tags}
                label={getLabel}
                title={getTitle}
            />
        </Layout>
      </PageContext.Provider>
    );
}

export default UniversalArchive

export const query = graphql`
    query getAllBlogPosts($skip: Int!, $limit: Int!, $field_component: String!, $tag: [String]) {
        posts: allStoryblokEntry(
            sort: {order: ASC, fields: position}
            limit: $limit
            skip: $skip
            filter: {
                field_component: {eq: $field_component}
                tag_list: {in: $tag}
            }
        ) {
            edges {
                node {
                    id
                    uuid
                    name
                    slug
                    full_slug
                    content
                    created_at
                    first_published_at
                    field_component
                    tag_list
                }
            }
        }
        settings: allStoryblokEntry(
            filter: {field_component: {eq: "settings"}}) {
            edges {
                node {
                    content
                }
            }
        }
    }
`;
