import React from "react"
import { Link } from "gatsby"
import resolveLink from "src/utils/resolveLink"

const ArchivePagination = ({props}) => {
  return (
    props.numPages > 1 &&
    <nav aria-label="Page navigation" className="py-12">
        <ul className="text-center space-x-2">
            {Array.from({ length: props.numPages }, (_, i) => (
                <li className="inline-block" key={i}>
                    <Link
                        className={`flex justify-center items-center hover:text-vivid duration-300 ${
                        props.currentPage === i + 1
                            ? "text-vivid border-t-2 border-vivid"
                            : "text-darkblue"
                        } text-base font-display p-4`}
                        key={`pagination-number${i + 1}`}
                        to={resolveLink(`/${props.pagination_slug}${props.tagSlug ? `/category/${props.tagSlug}/` : '/'}${i === 0 ? "" : i + 1 + "/"}`)}
                    >
                        <span>{i + 1}</span>
                    </Link>
                </li>
            ))}
        </ul>
    </nav>    
  )
}

export default ArchivePagination